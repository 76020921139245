import React from "react";
import lingsense from "../assets/images/LingSense_image_1.png";
import { IoCartOutline, IoChevronBackOutline, IoChevronForwardOutline} from "react-icons/io5";
import Gallery from "react-image-gallery";

function Card() {
  const images = [{original: lingsense}, {original: lingsense}]
  const customLeftNav = (onClick, disabled) => {
    return(
    <button
      type="button"
      className="image-gallery-icon image-gallery-left-nav "
      disabled={disabled}
      onClick={onClick}
      aria-label="Previous Slide"
    >
      <IoChevronBackOutline size={50}/>
    </button>
  );}
const customRightNav = (onClick, disabled) => {
    return(
    <button
      type="button"
      className="image-gallery-icon image-gallery-right-nav "
      disabled={disabled}
      onClick={onClick}
      aria-label="Previous Slide"
    >
      <IoChevronForwardOutline size={50}/>
    </button>
  );}

  return (
    <div className="container">
    <div className="card_container">
      <div className="card_img">
        <Gallery items={images} showPlayButton={false} showFullscreenButton={false} renderRightNav={customRightNav} renderLeftNav={customLeftNav}/>
      </div>
      <div className="information_container">
        <p className="container_new">New</p>
        <h1 className="card_title">LingSense</h1>
        <h4 className="card_subtitle">59.99€</h4>
        <p className="card_body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        <div className="certification"></div>
        <div className="card_footer">
        <button className="cart_btn btn">
          <p>  Add to cart </p> <div></div></button>
          <button className="details_btn btn">
          <p>  Get more details </p> <div></div></button>
        </div>
        
      </div>
    </div>
    </div>
  );
}

export default Card;