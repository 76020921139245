import React from "react";
import logo from "../assets/images/logo.svg";
import { IoPersonOutline, IoCartOutline } from "react-icons/io5";
import { stack as Menu } from 'react-burger-menu'

function Header() {
  const categories  = [{name:"Lights", link: "#" } , {name:"Sensor", link: "#" }, {name:"LingSync™", link: "#" }]
  return (
    <header className="header_area">
                  
      <div className="container">
        <div className="header_main">
          
          <div className="logo">
                <Menu >
                  {categories.map((categorie) =>(
                <a href={categorie.link}>{categorie.name}</a>
                  )
                  )}
                  

            </Menu>
            <a href="#">
              
              <img src={logo} alt="logo-img" />
              
            </a>
          </div>
          <div className="header_menu">
            <nav className="main_menu">
              <ul>
                {categories.map((categorie) =>(
                  <li>
                <a href={categorie.link}>{categorie.name}</a>
                </li>
                  )
                  )}

              </ul>
              
            </nav>
            
          </div>
          <div className="user_bar">
              <a href="https://auth.lingthings.net" className="account_btn icons">
                <IoPersonOutline size={30}/>
              </a>

              <a href="#" className="header_cart_btn icons">
                <IoCartOutline size={30}/>
              </a>
            </div>
          
        </div>
      </div>
    </header>
  );
}

export default Header;