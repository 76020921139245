import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Card from "./Card";
import { stack as Menu } from 'react-burger-menu'
function Home() {
  document.title = "Shop - Lingthings"
  return (
    <>

    <Header/> 
    <Card/>
    <Card/><Card/><Card/>
    </>
   );
}

export default Home;
