import logo from "./logo.svg";
import "./App.css";
import Home from "./components/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import sha256 from 'crypto-js/sha256';
function App() {
  if(!Cookies.get("_ud")) {
    const date = new Date(); 
var inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
    Cookies.set("_ud", sha256(uuidv4() + date), {expires: inFifteenMinutes, secure: true})
  } 
  return (
    <div className="App">
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />}/>
    
    </Routes>
      
    </BrowserRouter>
    </div>
  );
}

export default App;
